@media (min-width: 1280px) {
    body {
        .MuiContainer-maxWidthLg {
            max-width: 100%;
        }
    }
}

@media (max-width: 1700px) {
    .filter-container {
        flex-direction: column;

        .date-filter {
            width: 100%;
            max-width: 315px;
            margin-top: 10px;
        }
    }

    .graph-head {
        flex-direction: column;

    }

    .title-heading {
        text-align: center;
        margin-bottom: 10px;
    }
}